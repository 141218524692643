<template>
  <div class="home-container" ref="container" @click="handleDocument">
    <Header />
    <div class="contents">
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>

<script>
// import { paperDetails } from "@/apis/details"
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.$bus.$emit("mainScroll", this.$refs.container);
    },
    handleDocument(e) {
      this.$bus.$emit("mainDocument", e);
    },
  },
  // async   created() {
  // paperDetails()
  // let res= await  paperDetails({aa:'aa'})
  // console.log(res);
  // }
};
</script>

<style lang="scss" scoped>
.home-container {
  // background: #f9f9f9;
}
.contents {
  width: 100%;
  min-height: 700px;
  // min-width: 1500px;
  margin:0 auto;
}
</style>

<style lang="scss">
@media (max-width: 540px) {
  // .header-container {
  //   display: none !important;
  // }
  // .footer-container {
  //   display: none !important;
  // }
}
</style>
